import http from '@/utils/http.js'

// 合同列表/jasoboss/contract/add
export function contractList(data) {
    return http({
        url: "/jasoboss/contract/list",
        method: "post",
        data
    })
}
// 合同添加
export function contracAdd(data) {
    return http({
        url: "/jasoboss/contract/add",
        method: "post",
        data
    })
}
// 合同修改
export function contracUpDate(data) {
    return http({
        url: "/jasoboss/contract/update",
        method: "post",
        data
    })
}
// 合同删除
export function contracDelete(data) {
    return http({
        url: "/jasoboss/contract/delete",
        method: "post",
        data
    })
}
// 单个合同
export function contracGet(data) {
    return http({
        url: "/jasoboss/contract/get",
        method: "post",
        data
    })
}
// 合同审核
export function contracAudit(data) {
    return http({
        url: "/jasoboss/contract/audit/add",
        method: "post",
        data
    })
}
// 客户列表
export function customerList(data) {
    return http({
        url: "/jasoboss/customer/list/admin/id",
        method: "post",
        data
    })
}
// 客户列表
export function newCustomerList(data) {
    return http({
        url: "/jasoboss/customer/list/admin/deal",
        method: "post",
        data
    })
}
// 合同审核
export function customerTotal(data) {
    return http({
        url: "/jasoboss/contract/get/create/plan/total",
        method: "post",
        data
    })
}