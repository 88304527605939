<template>
    <div class="audit">
        <div class="content_box">
            <div
                class="contr_box"
                :style="{
                    background: 'url(' + require('@/assets/back.png') + ') ',
                }"
            >
                <p class="title">
                    <span> <i class="iconfont icon-hetong2"></i>合同信息 </span>
                    <span>
                        <el-button
                            @click="onClose()"
                            size="mini"
                            type="primary"
                            icon="el-icon-arrow-left"
                            style="background: #fff; color: #2370eb; border-color: #2370eb"
                            >返回列表</el-button
                        >
                    </span>
                </p>

                <el-form label-width="70px" label-position="left" size="small" ref="form">
                    <div class="contr_text">
                        <div>
                            <el-form-item label="合同编号">
                                <span class="text">{{ data.contractNo }}</span>
                            </el-form-item>
                            <el-form-item label="客户名称">
                                <span class="text">{{ data.companyName }}</span>
                            </el-form-item>
                            <el-form-item label="合同金额">
                                <span class="text">{{ data.contractAmount }}</span>
                            </el-form-item>
                            <el-form-item label="支付方式">
                                <span class="text">{{
                                    data.payWay == 1 ? '网银' : data.payWay == 2 ? '现金' : '- -'
                                }}</span>
                            </el-form-item>
                            <el-form-item label="附件">
                                <span
                                    class="cont"
                                    v-show="data.contractAttachmentBOList"
                                    v-for="item in data.contractAttachmentBOList"
                                >
                                    <el-image
                                        v-if="
                                            item.attachmentUrl.indexOf('.jpg') != -1 ||
                                            item.attachmentUrl.indexOf('.png') != -1 ||
                                            item.attachmentUrl.indexOf('.gif') != -1
                                        "
                                        style="width: 100px; height: 100px"
                                        :src="item.attachmentUrl"
                                        :preview-src-list="[item.attachmentUrl]"
                                    >
                                    </el-image>
                                    <span
                                        class="pdf"
                                        v-if="
                                            item.attachmentUrl.indexOf('.pdf') != -1 ||
                                            item.attachmentUrl.indexOf('.PDF') != -1
                                        "
                                        @click="onPreview(item.attachmentUrl)"
                                        style="cursor: pointer"
                                    >
                                        <img src="@/img/pdfUrl.png" alt="" />
                                    </span>
                                    <span
                                        class="word"
                                        v-if="item.attachmentUrl.indexOf('.doc') != -1"
                                        @click="onPreview(item.attachmentUrl)"
                                        style="cursor: pointer"
                                    >
                                        <img src="@/img/word.png" alt="" />
                                    </span>
                                </span>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item label="甲方">
                                <span class="text">{{ data.contractA }}</span>
                            </el-form-item>
                            <el-form-item label="甲方代表">
                                <span class="text">{{ data.representA }}</span>
                            </el-form-item>
                            <el-form-item label="乙方">
                                <span class="text" v-if="data.typeB != 9">
                                    {{ $tableDataHandle.typeB(data.typeB) }}
                                </span>
                                <span class="text" v-if="data.typeB == 9">{{
                                    data.contractB
                                }}</span>
                            </el-form-item>
                            <el-form-item label="乙方代表">
                                <span class="text">{{ data.representB }}</span>
                            </el-form-item>
                            <el-form-item label="营业执照">
                                <span class="cont" v-if="data.businessLicenseUrl">
                                    <el-image
                                        v-if="
                                            data.businessLicenseUrl.indexOf('.jpg') != -1 ||
                                            data.businessLicenseUrl.indexOf('.png') != -1 ||
                                            data.businessLicenseUrl.indexOf('.gif') != -1
                                        "
                                        style="width: 100px; height: 100px"
                                        :src="data.businessLicenseUrl"
                                        :preview-src-list="[data.businessLicenseUrl]"
                                    >
                                    </el-image>
                                    <span
                                        class="pdf"
                                        v-if="
                                            data.businessLicenseUrl.indexOf('.pdf') != -1 ||
                                            data.businessLicenseUrl.indexOf('.PDF') != -1
                                        "
                                        @click="onPreview(data.businessLicenseUrl)"
                                        style="cursor: pointer"
                                    >
                                        <img src="@/img/pdfUrl.png" alt=""
                                    /></span>
                                    <span
                                        class="word"
                                        v-if="data.businessLicenseUrl.indexOf('.doc') != -1"
                                        @click="onPreview(data.businessLicenseUrl)"
                                        style="cursor: pointer"
                                    >
                                        <img src="@/img/word.png" alt="" />
                                    </span>
                                </span>
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
            </div>
            <div class="target_box">
                <p class="title">合同标的</p>
                <el-table
                    class="table-box"
                    ref="multipleTable"
                    :data="tableData"
                    :header-cell-style="{
                        background: '#FAFAFA',
                        color: '#333333',
                        'font-size': '14px',
                        'font-weight': '500',
                    }"
                >
                    <el-table-column label="产品类别" show-overflow-tooltip>
                        <template slot-scope="scope"
                            >{{ $tableDataHandle.productCategory(scope.row.productCategory) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="版本" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-if="scope.row.productCategory == 1">{{
                                scope.row.version == 1
                                    ? '老版'
                                    : scope.row.version == 2
                                    ? '新版'
                                    : '- -'
                            }}</span>
                            <span v-else-if="scope.row.productCategory == 2">{{
                                scope.row.version == 1
                                    ? '标准版'
                                    : scope.row.version == 2
                                    ? '尊享版'
                                    : '集团版'
                            }}</span>
                            <span v-else>- -</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="购买类型" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span
                                v-if="
                                    scope.row.productCategory == 1 ||
                                    scope.row.productCategory == 2 ||
                                    scope.row.productCategory == 4 ||
                                    scope.row.productCategory == 7 ||
                                    scope.row.productCategory == 10 ||
                                    scope.row.productCategory == 14 ||
                                    scope.row.productCategory == 12 ||
                                    scope.row.productCategory == 5 ||
                                    scope.row.productCategory == 8
                                "
                                >{{
                                    scope.row.productType == 1
                                        ? '购买'
                                        : scope.row.productType == 2
                                        ? '续费'
                                        : scope.row.productType == 3
                                        ? '增购'
                                        : '- -'
                                }}</span
                            >
                            <span v-else-if="scope.row.productCategory == 3">{{
                                scope.row.productType == 1
                                    ? '腾讯云'
                                    : scope.row.productType == 2
                                    ? '阿里云'
                                    : '- -'
                            }}</span>
                            <span v-else>- -</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="用户数" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span
                                v-if="
                                    scope.row.productCategory != 5 &&
                                    scope.row.productCategory != 6 &&
                                    scope.row.productCategory != 7 &&
                                    scope.row.productCategory != 8 &&
                                    scope.row.productCategory != 9 &&
                                    scope.row.productCategory != 12 &&
                                    scope.row.productCategory != 3
                                "
                                >{{ scope.row.number }} 用户</span
                            >
                            <span v-else>- -</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="年限" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span
                                v-if="
                                    scope.row.productCategory != 5 &&
                                    scope.row.productCategory != 6 &&
                                    scope.row.productCategory != 7 &&
                                    scope.row.productCategory != 8 &&
                                    scope.row.productCategory != 9 &&
                                    scope.row.productCategory != 10 &&
                                    scope.row.productCategory != 11 &&
                                    scope.row.productCategory != 12 &&
                                    scope.row.productType != 3
                                "
                                >{{ scope.row.term }} 年</span
                            >
                            <span v-else>- -</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="金额" prop="receivableAmount" show-overflow-tooltip>
                    </el-table-column>

                    <div slot="empty" class="empty" style="padding: 50px 0">
                        <img src="@/assets/zanwushuju.png" />
                        <span style="display: block">暂无数据～</span>
                    </div>
                </el-table>
                <div class="statistics">
                    <div class="total">
                        <p>合计</p>
                        <p>
                            {{ total }}
                            <span style="font-size: 13px">元</span>
                        </p>
                    </div>
                    <div class="detailed">
                        <p>明细</p>
                        <p>{{ data.remark }}</p>
                    </div>
                </div>
            </div>
            <div class="operation_box">
                <p class="title">
                    <span></span>
                    审批
                </p>

                <el-form
                    label-width="90px"
                    label-position="left"
                    style="padding-left: 22px"
                    size="small"
                >
                    <el-form-item label="审批结果" required>
                        <el-radio v-model="status" :label="1">通过</el-radio>
                        <el-radio v-model="status" :label="0">驳回</el-radio>
                    </el-form-item>
                    <el-form-item label="审批备注" required>
                        <el-input
                            type="textarea"
                            style="width: 500px"
                            :rows="4"
                            placeholder="请输入（通过/驳回理由）"
                            v-model="remark"
                        >
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="bottom-but">
            <span>
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="background: none; border-color: #d5d6df; color: #666666"
                    @click="onClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 20px;
                    "
                    size="mini"
                    @click="onSubmit"
                    >保存</el-button
                ></span
            >
        </div>
    </div>
</template>
<script>
import { contracGet, contracAudit } from '@/api/contr/contr.js';
export default {
    data() {
        return {
            data: {},
            remark: '',
            status: 1,
            total: 0,
            tableData: [],
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            let data = {
                param: {
                    id: this.$route.query.id,
                },
            };
            contracGet(data).then((res) => {
                // console.log(res);
                this.data = res.data;
                this.tableData = res.data.contractTargetBOList;
                let price = res.data.contractTargetBOList.reduce(
                    (sum, e) => sum + Number(e.receivableAmount || 0),
                    0,
                );
                this.total = price;
            });
        },
        // 预览图片
        handlePictureCardPreview(file) {
            // console.log(file);
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },

        onPreview(url) {
            // console.log(url);
            if (url.indexOf('.doc') != -1 || url.indexOf('.docx') != -1) {
                window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url);
            } else {
                window.open(url, '_blank');
            }
        },
        onSubmit() {
            if (!this.remark) {
                return this.$message.error('请输入审核备注');
            }
            let data = {
                param: {
                    contractId: this.data.id,
                    remark: this.remark,
                    status: this.status,
                },
            };
            contracAudit(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('审批成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            window.history.go(-1);
        },
    },
};
</script>
<style lang="scss" scoped>
.audit {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .content_box {
        overflow: auto;
        flex: 1;
    }
    .contr_box {
        .title {
            padding: 16px 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 16px;
            color: #333333;
            .iconfont {
                color: #2370eb;
                font-size: 18px;
                margin-right: 14px;
            }
        }
        .contr_text {
            display: flex;
            padding-left: 48px;
            div {
                flex: 1;
                .text {
                    color: #333333;
                }
            }
        }
    }
    .title {
        padding: 16px;
        font-size: 14px;
        color: #333333;
    }
    .target_box {
        margin-top: 12px;
        background: #fff;
        border-radius: 4px;

        .statistics {
            display: flex;
            padding: 12px 32px 0 23px;
            padding-bottom: 20px;
            div {
                background: #f0f4ff;
                border-radius: 7px;
            }
            .total {
                width: 230px;
                height: 85px;
                padding-left: 15px;
                font-weight: 600;
                p:nth-child(1) {
                    font-size: 14px;
                    color: #333333;
                    margin: 12px 0;
                }
            }
            .detailed {
                flex: 1;
                margin-left: 24px;
                padding-left: 15px;
                p:nth-child(1) {
                    font-size: 14px;
                    color: #333333;
                    margin: 12px 0;
                    font-weight: 600;
                }
                .el-textarea__inner {
                    border: none !important;
                }
            }
        }
    }
    .bottom-but {
        width: 100%;
        height: 48px;
        background: #fff;
        display: flex;
        align-items: center;
        border-top: 1px solid #e5e5e5;
        justify-content: center;
        button {
            width: 90px;
        }
    }
    .operation_box {
        background: #fff;
        margin-top: 12px;
        padding-bottom: 50px;
        flex: 1;
    }
}
</style>
<style>
.audit .el-form--label-left .el-form-item__label {
    color: #666666 !important;
}
.audit .el-form-item__label {
    color: #666 !important;
}
.audit .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: 5px !important;
}
.el-radio__input.is-checked .el-radio__inner {
    border-color: #006df3;
    background: #006df3;
}
.el-radio__input.is-checked + .el-radio__label {
    color: #666;
}
</style>
